import { NgxMatDatetimePickerModule, NgxMatNativeDateModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { ObserversModule } from '@angular/cdk/observers';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';
import { DocumentCenterModule } from '@app/document-center/document-center.module';
import { AccountManagerInputComponent } from '@app/form/components/account-manager-input/account-manager-input.component';
import { AssetInputComponent } from '@app/form/components/asset-input/asset-input.component';
import { BasicFileInputComponent } from '@app/form/components/basic-file-input/basic-file-input.component';
import { ColorInputComponent } from '@app/form/components/color-input/color-input.component';
import { CoreTableComponent } from '@app/form/components/core-table/core-table.component';
import { DateTimeInputComponent } from '@app/form/components/date-time-input/date-time-input.component';
import { EnergyDocumentAssetsInputComponent } from '@app/form/components/energy-document-assets-input/energy-document-assets-input.component';
import { EnergyMeterReadingAssetsInputComponent } from '@app/form/components/energy-meter-reading-assets-input/energy-meter-reading-assets-input.component';
import { InvoicingAssetInputComponent } from '@app/form/components/invoicing-asset-input/invoicing-asset-input.component';
import { LanguageInputComponent } from '@app/form/components/language-input/language-input.component';
import { LocationsInputComponent } from '@app/form/components/locations-input/locations-input.component';
import { OfficesInputComponent } from '@app/form/components/offices-input/offices-input.component';
import { QuarterInputComponent } from '@app/form/components/quarter-input/quarter-input.component';
import { RealEstateGroupInputComponent } from '@app/form/components/real-estate-group-input/real-estate-group-input.component';
import { SelectLanguageComponent } from '@app/form/components/select-language/select-language.component';
import { SupplierGroupInputComponent } from '@app/form/components/supplier-group-input/supplier-group-input.component';
import { SupplierInputComponent } from '@app/form/components/supplier-input/supplier-input.component';
import { WaterDocumentAssetsInputComponent } from '@app/form/components/water-document-assets-input/water-document-assets-input.component';
import { WaterMeterReadingAssetsInputComponent } from '@app/form/components/water-meter-reading-assets-input/water-meter-reading-assets-input.component';
import { TranslationModule } from '@app/translation/translation.module';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule, IconModule, SvgModule, UiFormModule } from '@smooved/ui';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { ColorPickerModule } from 'ngx-color-picker';
import { NgxMaskModule } from 'ngx-mask';
import { QuillModule } from 'ngx-quill';
import { AccountNumberInputComponent } from './components/account-number-input/account-number-input.component';
import { AddressComponent } from './components/address/address.component';
import { AssetsInputComponent } from './components/assets-input/assets-input.component';
import { BaseStepComponent } from './components/base-step/base-step.component';
import { ButtonComponent } from './components/button/button.component';
import { CopyToClipboardComponent } from './components/copy-to-clipboard/copy-to-clipboard.component';
import { EanCodeElectricityDeprecatedComponent } from './components/ean-code-electricity-deprecated/ean-code-electricity.component';
import { EanCodeGasDeprecatedComponent } from './components/ean-code-gas-deprecated/ean-code-gas.component';
import { EanCodeInputComponent } from './components/ean-code-input/ean-code-input.component';
import { EscalationCategoriesInputComponent } from './components/escalation-categories-input/escalation-categories-input.component';
import { FileInputComponent } from './components/file-input/file-input.component';
import { MovingDateInputComponent } from './components/moving-date-input/moving-date-input.component';
import { MultiSelectInputComponent } from './components/multi-select-input/multi-select-input.component';
import { NationalNumberInputComponent } from './components/national-number-input/national-number-input.component';
import { NumberInputComponent } from './components/number-input/number-input.component';
import { PassportNumberInputComponent } from './components/passport-number-input/passport-number-input.component';
import { PasswordInputComponent } from './components/password-input/password-input.component';
import { PhoneInputComponent } from './components/phone-input/phone-input.component';
import { ScaleInputComponent } from './components/scale-input/scale-input.component';
import { ScoreInputComponent } from './components/score-input/score-input.component';
import { SubmitComponent } from './components/submit/submit.component';
import { TelecomBundleSelectionComponent } from './components/telecom-bundle-selection/telecom-bundle-selection.component';
import { TextSearchInputComponent } from './components/text-search-input/text-search-input.component';
import { ThumbnailComponent } from './components/thumbnail/thumbnail.component';
import { UnitInputComponent } from './components/unit-input/unit-input.component';
import { WaterIdCardAssetsInputComponent } from './components/water-id-card-assets-input/water-id-card-assets-input.component';
import { AuthImagePipe } from './pipes/auth-image.pipe';

@NgModule({
    imports: [
        CommonModule,
        MatFormFieldModule,
        MatInputModule,
        NgxMaskModule,
        MatDatepickerModule,
        TranslateModule,
        MatButtonModule,
        MatSelectModule,
        MatCheckboxModule,
        MatRadioModule,
        SvgModule,
        MatIconModule,
        AutocompleteLibModule,
        ClipboardModule,
        MatIconModule,
        QuillModule.forRoot({
            theme: 'snow',
        }),
        ColorPickerModule,
        RouterModule,
        TranslationModule,
        MatTableModule,
        MatSortModule,
        IconModule,
        ButtonModule,
        UiFormModule,
        ObserversModule,
        DocumentCenterModule,
        NgxMatDatetimePickerModule,
        NgxMatTimepickerModule,
        NgxMatNativeDateModule,
    ],
    declarations: [
        SelectLanguageComponent,
        NumberInputComponent,
        PasswordInputComponent,
        EanCodeElectricityDeprecatedComponent,
        EanCodeGasDeprecatedComponent,
        EanCodeInputComponent,
        PhoneInputComponent,
        MovingDateInputComponent,
        AddressComponent,
        SubmitComponent,
        DateTimeInputComponent,
        AccountNumberInputComponent,
        ButtonComponent,
        PassportNumberInputComponent,
        BaseStepComponent,
        ScaleInputComponent,
        CopyToClipboardComponent,
        NationalNumberInputComponent,
        FileInputComponent,
        BasicFileInputComponent,
        EnergyMeterReadingAssetsInputComponent,
        EnergyDocumentAssetsInputComponent,
        WaterMeterReadingAssetsInputComponent,
        WaterDocumentAssetsInputComponent,
        WaterIdCardAssetsInputComponent,
        ThumbnailComponent,
        AssetInputComponent,
        ColorInputComponent,
        RealEstateGroupInputComponent,
        AccountManagerInputComponent,
        SupplierInputComponent,
        SupplierGroupInputComponent,
        OfficesInputComponent,
        UnitInputComponent,
        QuarterInputComponent,
        CoreTableComponent,
        InvoicingAssetInputComponent,
        AuthImagePipe,
        ScoreInputComponent,
        MultiSelectInputComponent,
        TextSearchInputComponent,
        AssetsInputComponent,
        LanguageInputComponent,
        TelecomBundleSelectionComponent,
        EscalationCategoriesInputComponent,
        LocationsInputComponent,
    ],
    exports: [
        SelectLanguageComponent,
        UiFormModule,
        MatFormFieldModule,
        MatInputModule,
        NumberInputComponent,
        PasswordInputComponent,
        EanCodeElectricityDeprecatedComponent,
        EanCodeGasDeprecatedComponent,
        EanCodeInputComponent,
        PhoneInputComponent,
        MovingDateInputComponent,
        AddressComponent,
        SubmitComponent,
        ScaleInputComponent,
        DateTimeInputComponent,
        AccountNumberInputComponent,
        ButtonComponent,
        PassportNumberInputComponent,
        BaseStepComponent,
        AutocompleteLibModule,
        CopyToClipboardComponent,
        QuillModule,
        NationalNumberInputComponent,
        FileInputComponent,
        BasicFileInputComponent,
        EnergyMeterReadingAssetsInputComponent,
        EnergyDocumentAssetsInputComponent,
        WaterMeterReadingAssetsInputComponent,
        WaterDocumentAssetsInputComponent,
        WaterIdCardAssetsInputComponent,
        ThumbnailComponent,
        AssetInputComponent,
        ColorInputComponent,
        AccountManagerInputComponent,
        RealEstateGroupInputComponent,
        SupplierInputComponent,
        SupplierGroupInputComponent,
        OfficesInputComponent,
        UnitInputComponent,
        QuarterInputComponent,
        CoreTableComponent,
        InvoicingAssetInputComponent,
        AuthImagePipe,
        ScoreInputComponent,
        MultiSelectInputComponent,
        TextSearchInputComponent,
        AssetsInputComponent,
        LanguageInputComponent,
        TelecomBundleSelectionComponent,
        EscalationCategoriesInputComponent,
        LocationsInputComponent,
    ],
})
export class FormModule {}
