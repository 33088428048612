<app-closable-modal-template>
    <span modal-header>{{ (form.controls.isPublic.value ? 'COMMON.NOTES.PUBLIC' : 'COMMON.NOTES.INTERNAL') | translate }}</span>
    <form [formGroup]="form">
        <div class="u-flex u-flex-justify-content-space-between u-margin-bottom">
            <div>{{ 'COMMON.NOTES.PUBLIC' | translate }}</div>
            <mat-slide-toggle [formControlName]="formFields.IsPublic"></mat-slide-toggle>
        </div>
        <app-textarea-input
            [formControlName]="formFields.Note"
            [hasMarginDouble]="true"
            [hasMargin]="false"
            [rows]="8"
            width="21.8rem"
            [label]="'NOTES.NOTE_MODAL.TEXT_INPUT_LABEL' | translate"
            id="note"
        >
            <ng-container hint>
                <div *ngIf="!form.get(formFields.IsPublic).value; else placeholder">
                    <app-icon icon="lock"></app-icon>
                    <span class="u-margin-left-xs">{{ 'NOTES.NOTE_MODAL.FOOTER' | translate }}</span>
                </div>
                <!-- avoid modal resizing -->
                <ng-template #placeholder>&nbsp;</ng-template>
            </ng-container>
        </app-textarea-input>
    </form>
    <button app-button appearance="link" *ngIf="!!data.note" (click)="delete()">{{ 'COMMON.DELETE' | translate }}</button>
    <button app-button (click)="submit()">{{ 'SEND' | translate }}</button>
</app-closable-modal-template>
