import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DbUtils, Language, MetaReviewService, NpsReview, UrlUtils } from '@smooved/core';
import { Observable, of, zip } from 'rxjs';
import { first, tap } from 'rxjs/operators';
import { environment } from '../../../../../../smooved-match/src/environments/environment';
import { buildGoogleRatingDataForReview } from '../../../../../../smooved-match/src/ssr/constants/build-google-rating-data-for-review';
import { ButtonAppearance } from '../../../button';
import { AnalyticsService } from '../../../reviews';
import { ExternalInfluencerService } from '../../services';

const reviewKey = makeStateKey<NpsReview>('review');

@Component({
    selector: 'app-single-review',
    templateUrl: './single-review.component.html',
})
export class SingleReviewComponent implements OnInit, OnDestroy {
    @Input() public showBack = true;

    public readonly buttonAppearance = ButtonAppearance;
    public overviewRouterLink: string;
    public review: NpsReview;

    constructor(
        public readonly externalInfluencerService: ExternalInfluencerService,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly transferState: TransferState,
        private readonly analyticsService: AnalyticsService,
        private readonly metaReviewService: MetaReviewService,
        private readonly translateService: TranslateService
    ) {}

    public ngOnInit(): void {
        const { id } = this.route.snapshot.params;
        this.setMetaData(id);
        const { parentFragments } = UrlUtils.getActivatedRoute(this.route.snapshot);
        this.overviewRouterLink = parentFragments.join('/');
    }

    public ngOnDestroy(): void {
        this.metaReviewService.removeNoIndexMetaTag();
    }

    private setMetaData(reviewId: string): void {
        this.metaReviewService.addNoIndexMetaTag();
        zip([this.getReview(reviewId), this.externalInfluencerService.getExternalInfluencerFromTransferState()])
            .pipe(
                first(),
                tap(([review, externalInfluencer]) => {
                    this.review = review;

                    const language = Language[this.translateService.currentLang.toUpperCase() as keyof typeof Language];

                    const title = this.metaReviewService.getTitleSingleReview(externalInfluencer, review);
                    const description = review.suggestion;
                    this.metaReviewService.set({ title, description });
                    this.metaReviewService.addAlternateLanguageLinks(
                        environment.houseMatchCompanyPageUri,
                        externalInfluencer.url,
                        DbUtils.getStringId(review)
                    );
                    this.metaReviewService.addMetaData(
                        externalInfluencer,
                        review,
                        language,
                        description,
                        title,
                        environment.houseMatchCompanyPageUri,
                        environment.production,
                        environment.npsOgImageBaseUri
                    );
                    this.metaReviewService.addGoogleRatingScript(buildGoogleRatingDataForReview(externalInfluencer, review, language));
                    this.metaReviewService.addCanonicalLink(`${environment.houseMatchCompanyPageUri}${this.router.url}`);
                })
            )
            .subscribe();
    }

    private getReview(id: string): Observable<NpsReview> {
        const review = this.transferState.get<NpsReview>(reviewKey, null);
        if (this.transferState.hasKey(reviewKey) && review) {
            this.transferState.remove(reviewKey);
            return of(review);
        } else {
            return this.analyticsService.getReview(DbUtils.getStringId(id)).pipe(
                tap((review) => {
                    this.transferState.set(reviewKey, review);
                })
            );
        }
    }
}
