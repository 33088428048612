import { Component, Inject } from '@angular/core';
import { CompanionSignupBottomSheet } from '@app/real-estate-agent/bottom-sheet/companion-signup.bottom-sheet';
import { FEATURE_TOGGLE_CONFIG, FeatureScope, FeatureScopeSandbox, FeatureToggle, FeatureToggleConfig } from '@smooved/core';
import { BottomSheetSandbox } from '@smooved/ui';
import { LocalStorageKeys } from '../../../storage/sandboxes/storage.constants';
import { StorageSandbox } from '../../../storage/sandboxes/storage.sandbox';

@Component({
    selector: 'smvd-app-real-estate-agent-wrapper',
    template: `<router-outlet></router-outlet>`,
})
export class RealEstateAgentWrapperContainer {
    constructor(
        private readonly bottomSheetSandbox: BottomSheetSandbox,
        private readonly featureScopeSandbox: FeatureScopeSandbox,
        private readonly storageSandbox: StorageSandbox,
        @Inject(FEATURE_TOGGLE_CONFIG) private readonly config: FeatureToggleConfig
    ) {}

    public ngOnInit(): void {
        if (this.config[FeatureToggle.SmoovedCompanion])
            this.featureScopeSandbox
                .hasAccess(FeatureScope.SmoovedCompanion) // hasAccess will only take(1) result, no need of $destroy
                .subscribe(
                    (hasAccess) =>
                        !hasAccess &&
                        (this.storageSandbox.getToken(LocalStorageKeys.Companion) === 'show' ||
                            !this.storageSandbox.getToken(LocalStorageKeys.Companion)) &&
                        this.bottomSheetSandbox.open({
                            component: CompanionSignupBottomSheet,
                            panelClass: 'mat-bottom-sheet-corner',
                            backdropClass: 'mat-bottom-sheet-backdrop-corner',
                        })
                );
    }
}
