<form id="gas-form" class="__gas-form" [formGroup]="gasForm">
    <div class="u-flex-row u-flex-justify-content-space-between u-margin-bottom">
        <div class="u-flex-row u-flex-justify-content-flex-start">
            <smvd-ui-svg-illustration
                [svg]="svgIllustration.MetersGas"
                class="u-icon-24 u-margin-right-half"
                [class.u-color-gray-dark]="activeControl.value"
                [class.u-color-gray-light]="!activeControl.value"
            ></smvd-ui-svg-illustration>
            <h5 [class.u-color-gray-light]="!activeControl.value">{{ 'GAS' | translate }}</h5>
        </div>
        <mat-slide-toggle *ngIf="gasForm.enabled" [formControlName]="fields.active"></mat-slide-toggle>
    </div>

    <ng-container *ngIf="activeControl.value; else inactive">
        <smvd-ui-text-input
            id="ean-code-field"
            width="auto"
            [formControlName]="fields.eanCodeGas"
            [label]="'EAN_NUMBER.LABEL' | translate"
            [placeholder]="placeholders.eanPlaceholder"
        ></smvd-ui-text-input>

        <smvd-ui-meter-reading-input
            width="100%"
            [formControlName]="fields.gasMeterReading"
            [label]="'METER_READING' | translate"
            [placeholder]="placeholders.consumptionMcLabel | translate"
        ></smvd-ui-meter-reading-input>
    </ng-container>
</form>

<ng-template #inactive
    ><p class="u-color-gray-dark" id="inactive-text">{{ 'ENERGY.METER_READING.INACTIVE.GAS' | translate }}</p></ng-template
>
