import { cacheBusterHash } from '@smooved/core';
import { mockFormData } from '@mock/mock-form-data.constants';

export const environment = {
    production: false,
    environmentName: 'development',
    initialData: false,
    mockFormData,
    appUri: 'https://app-development.smooved.be',
    serverUri: 'https://smooved-api-development.herokuapp.com',
    apiUri: 'https://smooved-api-development.herokuapp.com/api/v1',
    faqUri: 'https://smooved.be/faq',
    termsAndConditionsUri: {
        nl: 'https://www.smooved.be/algemene-voorwaarden/',
        fr: 'https://www.smooved.be/algemene-voorwaarden/',
        en: 'https://www.smooved.be/algemene-voorwaarden/',
    },
    privacyUri: {
        nl: 'https://www.smooved.be/privacy/',
        fr: 'https://www.smooved.be/privacy/',
        en: 'https://www.smooved.be/privacy/',
    },
    widgetUri: 'https://widgets-test.smooved.be',
    widgetBootstrapScript: 'https://widgets-test.smooved.be/bootstrap/v2.bootstrap.min.js',
    widgetBootstrapName: 'v2.bootstrap-test',
    services: {
        telecom: true,
        energy: true,
        water: true,
        insurance: true,
        logistics: true,
        post: true,
        iots: false,
    },
    agreementUri: 'https://smooved-agreements-test.netlify.com',
    featureFlags: {
        nps: true,
    },
    showTestWebhook: false,
    showBeamer: true,
    externalInfluencerUri: 'http://influencers-test.smooved.be',
    telecomBannerUri: 'https://smooved-commercial-test.s3.eu-west-3.amazonaws.com/telecom/banner.png',
    telecomBannerFlashUri: 'https://smooved-commercial-test.s3.eu-west-3.amazonaws.com/telecom/banner-flash.png',
    telecomBannerFlash: true,
    cacheBuster: cacheBusterHash,
    houseMatchUri: 'https://app-test.housematch.be',
    houseMatchCompanyPageUri: 'https://app-test.housematch.be',
    insurancesPartner: 'Yago',
    packageInfoUri: {
        nl: 'https://smooved.be/pakketten/',
        fr: 'https://smooved.be/fr/formules/',
        en: 'https://smooved.be/pakketten/',
    },
    mailTo: {
        nl: 'verhuizen@smooved.be',
        fr: 'demenager@smooved.be',
        en: 'verhuizen@smooved.be',
    },
    featureToggle: {
        smoovedCompanion: true,
    },
};
