import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AnalyticsModule } from '@app/analytics/analytics.module';
import { DocumentCenterModule } from '@app/document-center/document-center.module';
import { ExternalInfluencerModule } from '@app/external-influencer/external-influencer.module';
import { FeatureScopeModule } from '@app/feature-scope/feature-scope.module';
import { GiftModule } from '@app/gift/gift.module';
import { InvoicingModule } from '@app/invoicing/invoicing.module';
import { MessageModule } from '@app/message/message.module';
import { MeterReadingsModule } from '@app/meter-readings/meter-readings.module';
import { MoveWizardModule } from '@app/move-wizard/move-wizard.module';
import { MoveTrackEnergyComponent } from '@app/move/components/move-track-energy/move-track-energy.component';
import { MoveModule } from '@app/move/move.module';
import { OrderModule } from '@app/order/order.module';
import { EnergyOptimizationComponent } from '@app/real-estate-agent/components/energy-optimization/energy-optimization.component';
import { InsightsClientsSourceLegendComponent } from '@app/real-estate-agent/components/insights-clients-source-legend/insights-clients-source-legend.component';
import { InsightsClientsSourceChartComponent } from '@app/real-estate-agent/components/insights-clients-source/insights-clients-source-chart.component';
import { InsightsHouseMatchScoreInfoComponent } from '@app/real-estate-agent/components/insights-house-match-score-info/insights-house-match-score-info.component';
import { InsightsHouseMatchScoreComponent } from '@app/real-estate-agent/components/insights-house-match-score/insights-house-match-score.component';
import { InsightsReviewsPerMonthLegendComponent } from '@app/real-estate-agent/components/insights-reviews-per-month-legend/insights-reviews-per-month-legend.component';
import { InsightsReviewsPerMonthChartComponent } from '@app/real-estate-agent/components/insights-reviews-per-month/insights-reviews-per-month-chart.component';
import { InsightsUpgradeInfoComponent } from '@app/real-estate-agent/components/insights-upgrade-info/insights-upgrade-info.component';
import { InvoicesBillingChangesInfoComponent } from '@app/real-estate-agent/components/invoices-billing-changes-info/invoices-billing-changes-info.component';
import { IotsComponent } from '@app/real-estate-agent/components/iots/iots.component';
import { MoveDetailWaterSection } from '@app/real-estate-agent/components/move-detail-water-section/move-detail-water-section';
import { NpsActionsFullComponent } from '@app/real-estate-agent/components/nps-actions/nps-actions-full.component';
import { NpsComponent } from '@app/real-estate-agent/components/nps/nps.component';
import { OtherSimulationToolsComponent } from '@app/real-estate-agent/components/other-simulation-tools/other-simulation-tools.component';
import { SettingsDetailsMenuComponent } from '@app/real-estate-agent/components/settings-details-menu/settings-details-menu.component';
import { TopicAnalysisResultsReviewsComponent } from '@app/real-estate-agent/components/topic-analysis-results-reviews/topic-analysis-results-reviews.component';
import { TopicAnalysisNoResultsPipe } from '@app/real-estate-agent/components/topic-analysis-results/topic-analysis-no-results.pipe';
import { TopicAnalysisResultsComponent } from '@app/real-estate-agent/components/topic-analysis-results/topic-analysis-results.component';
import { TopicAnalysisResultsPipe } from '@app/real-estate-agent/components/topic-analysis-results/topic-analysis-results.pipe';
import { TopicAnalysisSentimentComponent } from '@app/real-estate-agent/components/topic-analysis-sentiment/topic-analysis-sentiment.component';
import { FeatureDetailGiftsContainer } from '@app/real-estate-agent/containers/feature-detail-gifts/feature-detail-gifts.container';
import { FeatureDetailInsurancesContainer } from '@app/real-estate-agent/containers/feature-detail-insurances/feature-detail-insurances.container';
import { FeatureDetailMortgageContainer } from '@app/real-estate-agent/containers/feature-detail-mortgage/feature-detail-mortgage.container';
import { FeatureDetailRentalInspectionContainer } from '@app/real-estate-agent/containers/feature-detail-rental-inspection/feature-detail-rental-inspection.container';
import { FeatureDetailReviewsContainer } from '@app/real-estate-agent/containers/feature-detail-reviews/feature-detail-reviews.container';
import { FeatureDetailTotsContainer } from '@app/real-estate-agent/containers/feature-detail-tots/feature-detail-tots.container';
import { FeatureDetailWaterContainer } from '@app/real-estate-agent/containers/feature-detail-water/feature-detail-water.container';
import { InsightsContainer } from '@app/real-estate-agent/containers/insights/insights.container';
import { OfficeContainer } from '@app/real-estate-agent/containers/office/office.container';
import { SettingsOfficesContainer } from '@app/real-estate-agent/containers/settings-offices/settings-offices.container';
import { CreateOfficeModal } from '@app/real-estate-agent/modals/create-office/create-office.modal';
import { EnergyDocumentAssetDetailComponent } from '@app/real-estate-agent/modals/energy-document-asset-detail/energy-document-asset-detail.component';
import { EnergyMeterReadingAssetDetailComponent } from '@app/real-estate-agent/modals/energy-meter-reading-asset-detail/energy-meter-reading-asset-detail.component';
import { MobileEnergyDocumentAssetDetailComponent } from '@app/real-estate-agent/modals/mobile-energy-document-asset-detail/mobile-energy-document-asset-detail.component';
import { MobileMeterInfoElectricityComponent } from '@app/real-estate-agent/modals/mobile-meter-info-electricity/mobile-meter-info-electricity.component';
import { MobileMeterInfoGasComponent } from '@app/real-estate-agent/modals/mobile-meter-info-gas/mobile-meter-info-gas.component';
import { MobileMeterReadingAssetDetailComponent } from '@app/real-estate-agent/modals/mobile-meter-reading-asset-detail/mobile-meter-reading-asset-detail.component';
import { MobileMoveInfoComponent } from '@app/real-estate-agent/modals/mobile-move-info/mobile-move-info.component';
import { MobileMoveOverviewComponent } from '@app/real-estate-agent/modals/mobile-move-overview/mobile-move-overview.component';
import { MobileMoveWaterComponent } from '@app/real-estate-agent/modals/mobile-move-water/mobile-move-water.component';
import { MobileWaterDocumentAssetDetailComponent } from '@app/real-estate-agent/modals/mobile-water-document-asset-detail/mobile-water-document-asset-detail.component';
import { MoveDetailCompleteWaterDataModal } from '@app/real-estate-agent/modals/move-detail-complete-water-data/move-detail-complete-water-data.modal';
import { MoveDetailInsurancesComponent } from '@app/real-estate-agent/modals/move-detail-insurances/move-detail-insurances.component';
import { WaterDocumentAssetDetailComponent } from '@app/real-estate-agent/modals/water-document-asset-detail/water-document-asset-detail.component';
import { IsActiveRealEstateAgentPipe } from '@app/real-estate-agent/pipes/is-active-real-estate-agent/is-active-real-estate-agent.pipe';
import { IsNotActiveRealEstateAgentPipe } from '@app/real-estate-agent/pipes/is-not-active-real-estate-agent/is-not-active-real-estate-agent.pipe';
import { RealEstateAgentStateIconPipe } from '@app/real-estate-agent/pipes/real-estate-agent-state-icon/real-estate-agent-state-icon.pipe';
import { InsightsEffects } from '@app/real-estate-group/state/insights.effects';
import { insightsReducer, insightsStoreFeatureName } from '@app/real-estate-group/state/insights.reducer';
import { ReviewsModule } from '@app/reviews/reviews.module';
import { CheckItemModule } from '@app/ui/check-item/check-item.module';
import { ImageModule } from '@app/ui/image/image.module';
import { UiModule } from '@app/ui/ui.module';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { PipeModule as CorePipeModule, I18nKeyModule } from '@smooved/core';
import {
    AccordionModule,
    AlertModule,
    BottomSheetModule,
    ChartsModule,
    CompanyPageModule,
    EnergyModule,
    LoadingModule,
    NoteModule,
    SvgModule,
    TooltipModule,
    TrackAndTraceModule,
    UiModalModule,
    ReviewsModule as UiReviewsModule,
    WidgetModule as WidgetInfluencerScoreModule,
} from '@smooved/ui';
import { DirectiveModule } from '../../../projects/core/src/lib/directives/directive.module';
import { AddressModule } from '../address/address.module';
import { AgreementModule } from '../agreements/agreement.module';
import { CardModule } from '../card/card.module';
import { FormModule } from '../form/form.module';
import { ModalModule } from '../modal/modal.module';
import { SharedModule } from '../shared/shared.module';
import { WaterModule } from '../water/water.module';
import { LegalModule } from '../wizard/legal/legal.module';
import { CompanionSignupBottomSheet } from './bottom-sheet/companion-signup.bottom-sheet';
import { AgentFormComponent } from './components/agent-form/agent-form.component';
import { AnalyticsAttentionRequiredComponent } from './components/analytics-attention-required/analytics-attention-required.component';
import { AnalyticsDashboardComponent } from './components/analytics-dashboard/analytics-dashboard.component';
import { AnalyticsInfluencersComponent } from './components/analytics-influencers/analytics-influencers.component';
import { AnalyticsMoveCountTableComponent } from './components/analytics-move-count-table/analytics-move-count-table.component';
import { AnalyticsMovesCountByCreatedFlowChartComponent } from './components/analytics-moves-count-by-created-flow-chart/analytics-moves-count-by-created-flow-chart.component';
import { AnalyticsServicesComponent } from './components/analytics-services/analytics-services.component';
import { AnalyticsTransactionsComponent } from './components/analytics-transactions/analytics-transactions.component';
import { ConfirmationsLongPeriodChartComponent } from './components/confirmations-long-period-chart/confirmations-long-period-chart.component';
import { ConfirmationsShortPeriodChartComponent } from './components/confirmations-short-period-chart/confirmations-short-period-chart.component';
import { DashboardActionsComponent } from './components/dashboard-actions/dashboard-actions.component';
import { DashboardFilterComponent } from './components/dashboard-filter/dashboard-filter.component';
import { DashboardMenuComponent } from './components/dashboard-menu/dashboard-menu.component';
import { DashboardTableComponent } from './components/dashboard-table/dashboard-table.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { EnergyComponent } from './components/energy/energy.component';
import { HighwayInfoBoxComponent } from './components/highway/highway-info-box/highway-info-box.component';
import { LeadPassingComponent } from './components/lead-passing/lead-passing.component';
import { ManagementDetailsMenuComponent } from './components/management-details-menu/management-details-menu.component';
import { ManagementMenuComponent } from './components/management-menu/management-menu.component';
import { MetricsMenuComponent } from './components/metrics-menu/metrics-menu.component';
import { MortgageTableComponent } from './components/mortgage-table/mortgage-table.component';
import { MoveLeaverDetailWaterComponent } from './components/move-leaver-detail-water/move-leaver-detail-water.component';
import { MovingDateVsCreatedOnGapComponent } from './components/moving-date-vs-created-on-gap/moving-date-vs-created-on-gap.component';
import { NpsMeterComponent } from './components/nps-meter/nps-meter.component';
import { OfficeFilterComponent } from './components/office-filter/office-filter.component';
import { OfficeTableComponent } from './components/office-table/office-table.component';
import { PoweredByComponent } from './components/powered-by/powered-by.component';
import { ProgressComponent } from './components/progress/progress.component';
import { RealEstateAgentNavigationComponent } from './components/real-estate-agent-navigation/real-estate-agent-navigation.component';
import { ReviewsMenuComponent } from './components/reviews-menu/reviews-menu.component';
import { ServiceDropdownMenuComponent } from './components/service-dropdown-menu/service-dropdown-menu.component';
import { ServiceEnergyDocumentsMovingAddressByAdminComponent } from './components/service-energy-documents-moving-address-by-admin/service-energy-documents-moving-address-by-admin.component';
import { ServiceOrderedByAdminComponent } from './components/service-ordered-by-admin/service-ordered-by-admin.component';
import { ServiceOrderedComponent } from './components/service-ordered/service-ordered.component';
import { ServiceWaterByAdminComponent } from './components/service-water-by-admin/service-water-by-admin.component';
import { ServiceWaterByRealEstateAgentComponent } from './components/service-water-by-real-estate-agent/service-water-by-real-estate-agent.component';
import { StartTileComponent } from './components/start-tile/start-tile.component';
import { TableColumnNameComponent } from './components/table-column-name/table-column-name.component';
import { TodoEnergyEotsSentComponent } from './components/todo-energy-eots-sent/todo-energy-eots-sent.component';
import { TodoEnergyInvitedComponent } from './components/todo-energy-invited/todo-energy-invited.component';
import { TodoEnergyOrderedByAdminComponent } from './components/todo-energy-ordered-by-admin/todo-energy-ordered-by-admin.component';
import { TodoEnergyOrderedComponent } from './components/todo-energy-ordered/todo-energy-ordered.component';
import { TodoEnergyTransferMeterReadingsSentComponent } from './components/todo-energy-transfer-meter-readings-sent/todo-energy-transfer-meter-readings-sent.component';
import { TodoLeaverCreatedComponent } from './components/todo-leaver-created/todo-leaver-created.component';
import { TodoLeaverNotInterestedComponent } from './components/todo-leaver-not-interested/todo-leaver-not-interested.component';
import { TodoLeaverRegularizationBillRequestedComponent } from './components/todo-leaver-regularization-bill-requested/todo-leaver-regularization-bill-requested.component';
import { TodoLeaverSuppliersReceivedComponent } from './components/todo-leaver-suppliers-received/todo-leaver-suppliers-received.component';
import { TodoSettingTheSceneComponent } from './components/todo-setting-the-scene/todo-setting-the-scene.component';
import { AnalyticsReviewsContainer } from './containers/analytics-reviews/analytics-reviews.container';
import { DashboardContainerComponent } from './containers/dashboard/dashboard.container';
import { FinancialReportContainer } from './containers/financial-report/financial-report.container';
import { InvoicesContainer } from './containers/invoices/invoices.container';
import { MortgageOverviewContainer } from './containers/mortgage-overview/mortgage-overview.container';
import { QuarterReportContainer } from './containers/quarter-report/quarter-report.container';
import { RealEstateAgentStartContainer } from './containers/real-estate-agent-start/real-estate-agent-start.container';
import { SmoovedAnalyticsConfirmationsContainer } from './containers/smooved-analytics-confirmations/smooved-analytics-confirmations.container';
import { SmoovedAnalyticsInputsContainer } from './containers/smooved-analytics-inputs/smooved-analytics-inputs.container';
import { SmoovedAnalyticsOverviewContainerComponent } from './containers/smooved-analytics-overview/smooved-analytics-overview.container';
import { StartContainer } from './containers/start/start.container';
import { RealEstateAgentWrapperContainer } from './containers/wrapper/wrapper.container';
import { AgentFormModalComponent } from './modals/agent-form/agent-form.modal';
import { ConfigureSignatureModule } from './modals/configure-signature/configure-signature.modal';
import { ConfigureWizardInfluencerScoreModal } from './modals/configure-widget-influencer-score/configure-widget-influencer-score.modal';
import { FeeInformationModalComponent } from './modals/fee-information/fee-information.modal';
import { LateMovingDateReadMoreModalComponent } from './modals/late-moving-date-read-more/late-moving-date-read-more.modal';
import { MobileAgentFormModalComponent } from './modals/mobile-agent-form/mobile-agent-form.modal';
import { MobileMoveDetailInsurancesModalComponent } from './modals/mobile-move-detail-insurances/mobile-move-detail-insurances.component';
import { MobileMoveEnergyMetersComponent } from './modals/mobile-move-energy-meters/mobile-move-energy-meters.component';
import { MobileMoveGeneralDetailsComponent } from './modals/mobile-move-general-details/mobile-move-general-details.component';
import { MobileMoveLeaverGeneralDetailsComponent } from './modals/mobile-move-leaver-general-details/mobile-move-leaver-general-details.component';
import { MobileMoveLeaverOverviewComponent } from './modals/mobile-move-leaver-overview/mobile-move-leaver-overview.component';
import { MoveDetailTelecomComponent } from './modals/move-detail-telecom/move-detail-telecom.component';
import { MoveDetailWaterComponent } from './modals/move-detail-water/move-detail-water.component';
import { MoveDetailModalComponent } from './modals/move-detail/move-detail.modal';
import { MoveEditModalComponent } from './modals/move-edit/move-edit.modal';
import { MoveLeaverDetailModalComponent } from './modals/move-leaver-detail/move-leaver-detail.modal';
import { SurveyInviteModal } from './modals/survey-invite/survey-invite.modal';
import { WaterIdCardAssetDetailComponent } from './modals/water-id-card-asset-detail/water-id-card-asset-detail.component';
import { WaterMeterReadingAssetDetailComponent } from './modals/water-meter-reading-asset-detail copy/water-meter-reading-asset-detail.component';
import { AgentStatesPipe } from './pipes/agent-states/agent-states.pipe';
import { IsInactiveRealEstateAgentPipe } from './pipes/is-inactive-real-estate-agent/is-inactive-real-estate-agent.pipe';
import { IsNotInactiveRealEstateAgentPipe } from './pipes/is-not-inactive-real-estate-agent/is-not-inactive-real-estate-agent.pipe';
import { RealEstateAgentProvidersModule } from './real-estate-agent-providers.module';
import { RealEstateAgentRoutingModule } from './real-estate-agent-routing.module';
import { RealEstateAgentDashboardEffects } from './state/real-estate-agent-dashboard.effects';
import { realEstateAgentDashboardReducer } from './state/real-estate-agent-dashboard.reducer';
import { RealEstateAgentsEffects } from './state/real-estate-agents.effects';
import { realEstateAgentsReducer } from './state/real-estate-agents.reducer';

@NgModule({
    imports: [
        RealEstateAgentProvidersModule,
        CommonModule,
        AlertModule,
        SharedModule,
        ModalModule,
        FormModule,
        RealEstateAgentRoutingModule,
        AgreementModule,
        TranslateModule,
        MatDividerModule,
        MatExpansionModule,
        EnergyModule,
        LegalModule,
        CardModule,
        SvgModule,
        MatPaginatorModule,
        MatSortModule,
        MatMenuModule,
        MatDialogModule,
        MatIconModule,
        StoreModule.forFeature('real-estate-agent', realEstateAgentsReducer),
        EffectsModule.forFeature([RealEstateAgentsEffects]),
        StoreModule.forFeature('real-estate-agent-dashboard', realEstateAgentDashboardReducer),
        EffectsModule.forFeature([RealEstateAgentDashboardEffects]),
        StoreModule.forFeature(insightsStoreFeatureName, insightsReducer),
        EffectsModule.forFeature([InsightsEffects]),
        AddressModule,
        WaterModule,
        MatTooltipModule,
        MoveModule,
        AnalyticsModule,
        UiModule,
        AccordionModule,
        MoveWizardModule,
        InvoicingModule,
        ChartsModule,
        TooltipModule,
        ReviewsModule,
        UiReviewsModule,
        NoteModule,
        GiftModule,
        CheckItemModule,
        TrackAndTraceModule,
        UiModalModule,
        FeatureScopeModule,
        ImageModule,
        ExternalInfluencerModule,
        OrderModule,
        I18nKeyModule,
        WidgetInfluencerScoreModule,
        CorePipeModule,
        DocumentCenterModule,
        MessageModule,
        MeterReadingsModule,
        CompanyPageModule,
        DirectiveModule,
        LoadingModule,
        CompanyPageModule,
        MatSelectModule,
        MatRadioModule,
        EnergyModule,
        BottomSheetModule,
    ],
    declarations: [
        PoweredByComponent,
        StartContainer,
        StartTileComponent,
        RealEstateAgentStartContainer,
        DashboardComponent,
        EnergyComponent,
        LeadPassingComponent,
        OtherSimulationToolsComponent,
        DashboardContainerComponent,
        DashboardTableComponent,
        ProgressComponent,
        DashboardFilterComponent,
        RealEstateAgentNavigationComponent,
        MoveEditModalComponent,
        ServiceEnergyDocumentsMovingAddressByAdminComponent,
        ServiceOrderedComponent,
        ServiceOrderedByAdminComponent,
        ServiceWaterByRealEstateAgentComponent,
        ServiceWaterByAdminComponent,
        FeeInformationModalComponent,
        NpsMeterComponent,
        NpsComponent,
        EnergyOptimizationComponent,
        TableColumnNameComponent,
        MobileMoveGeneralDetailsComponent,
        MobileMoveOverviewComponent,
        MobileMoveInfoComponent,
        MobileMoveEnergyMetersComponent,
        MobileMoveWaterComponent,
        MobileMeterReadingAssetDetailComponent,
        MobileEnergyDocumentAssetDetailComponent,
        MobileWaterDocumentAssetDetailComponent,
        EnergyMeterReadingAssetDetailComponent,
        EnergyDocumentAssetDetailComponent,
        WaterDocumentAssetDetailComponent,
        WaterMeterReadingAssetDetailComponent,
        WaterIdCardAssetDetailComponent,
        MobileMeterInfoElectricityComponent,
        MobileMeterInfoGasComponent,
        MoveTrackEnergyComponent,
        MoveDetailTelecomComponent,
        MoveDetailWaterComponent,
        MoveDetailInsurancesComponent,
        MobileMoveDetailInsurancesModalComponent,
        OfficeContainer,
        OfficeFilterComponent,
        OfficeTableComponent,
        AgentFormModalComponent,
        AgentFormComponent,
        MobileAgentFormModalComponent,
        CreateOfficeModal,
        FeatureDetailReviewsContainer,
        FeatureDetailGiftsContainer,
        FeatureDetailMortgageContainer,
        FeatureDetailInsurancesContainer,
        FeatureDetailWaterContainer,
        FeatureDetailTotsContainer,
        FeatureDetailRentalInspectionContainer,
        SettingsOfficesContainer,
        IotsComponent,
        FinancialReportContainer,
        QuarterReportContainer,
        InvoicesContainer,
        MoveLeaverDetailModalComponent,
        MoveLeaverDetailWaterComponent,
        MobileMoveLeaverOverviewComponent,
        MobileMoveLeaverGeneralDetailsComponent,
        AnalyticsMoveCountTableComponent,
        ReviewsMenuComponent,
        MetricsMenuComponent,
        ManagementMenuComponent,
        ManagementDetailsMenuComponent,
        SettingsDetailsMenuComponent,
        SmoovedAnalyticsOverviewContainerComponent,
        SmoovedAnalyticsInputsContainer,
        AnalyticsMovesCountByCreatedFlowChartComponent,
        AnalyticsReviewsContainer,
        MovingDateVsCreatedOnGapComponent,
        SmoovedAnalyticsConfirmationsContainer,
        ConfirmationsShortPeriodChartComponent,
        ConfirmationsLongPeriodChartComponent,
        DashboardActionsComponent,
        MoveDetailModalComponent,
        ServiceDropdownMenuComponent,
        MortgageOverviewContainer,
        MortgageTableComponent,
        ConfigureWizardInfluencerScoreModal,
        ConfigureSignatureModule,
        AnalyticsDashboardComponent,
        AnalyticsTransactionsComponent,
        AnalyticsServicesComponent,
        DashboardMenuComponent,
        AnalyticsInfluencersComponent,
        AnalyticsAttentionRequiredComponent,
        TodoEnergyOrderedComponent,
        TodoEnergyInvitedComponent,
        TodoEnergyEotsSentComponent,
        TodoEnergyOrderedByAdminComponent,
        TodoEnergyTransferMeterReadingsSentComponent,
        TodoLeaverCreatedComponent,
        TodoLeaverSuppliersReceivedComponent,
        TodoLeaverRegularizationBillRequestedComponent,
        SurveyInviteModal,
        LateMovingDateReadMoreModalComponent,
        TodoLeaverNotInterestedComponent,
        TodoSettingTheSceneComponent,
        InsightsContainer,
        InsightsUpgradeInfoComponent,
        TopicAnalysisResultsComponent,
        TopicAnalysisSentimentComponent,
        TopicAnalysisResultsPipe,
        TopicAnalysisNoResultsPipe,
        TopicAnalysisResultsReviewsComponent,
        InsightsHouseMatchScoreComponent,
        InsightsHouseMatchScoreInfoComponent,
        InsightsReviewsPerMonthChartComponent,
        InsightsReviewsPerMonthLegendComponent,
        InsightsClientsSourceLegendComponent,
        InsightsClientsSourceChartComponent,
        NpsActionsFullComponent,
        InvoicesBillingChangesInfoComponent,
        MoveDetailWaterSection,
        MoveDetailCompleteWaterDataModal,
        IsActiveRealEstateAgentPipe,
        IsNotActiveRealEstateAgentPipe,
        IsInactiveRealEstateAgentPipe,
        AgentStatesPipe,
        RealEstateAgentStateIconPipe,
        IsNotInactiveRealEstateAgentPipe,
        CompanionSignupBottomSheet,
        RealEstateAgentWrapperContainer,
        HighwayInfoBoxComponent,
    ],
    exports: [
        PoweredByComponent,
        ServiceEnergyDocumentsMovingAddressByAdminComponent,
        ServiceWaterByAdminComponent,
        IsActiveRealEstateAgentPipe,
        IsNotActiveRealEstateAgentPipe,
        IsInactiveRealEstateAgentPipe,
        AgentStatesPipe,
        RealEstateAgentStateIconPipe,
        IsNotInactiveRealEstateAgentPipe,
    ],
})
export class RealEstateAgentModule {}
