<table matSort matSortDirection="desc" matSortDisableClear mat-table [dataSource]="dataSource" class="u-w100p">
    <ng-container matColumnDef="icon">
        <th class="__icon" mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row">
            <span *ngIf="row | isNotActiveRealEstateAgent">
                <img
                    src="https://assets.smooved.be/icons/archived.svg"
                    appTooltip
                    [template]="inactiveTooltip"
                    *ngIf="row | isInactiveRealEstateAgent; else isDraft"
                    class="__icon-images"
                    height="16px"
                    width="16px"
                />
                <ng-template #isDraft>
                    <img
                        src="https://assets.smooved.be/icons/mail.svg"
                        appTooltip
                        [template]="draftTooltip"
                        class="__icon-images"
                        height="18px"
                        width="18px"
                    />
                </ng-template>
            </span>
        </td>
    </ng-container>
    <ng-container matColumnDef="name">
        <th mat-header-cell class="u-padding-left" *matHeaderCellDef>{{ 'REAL_ESTATE_AGENT.OFFICE.TABLE.NAME' | translate }}</th>
        <td class="u-padding-left" mat-cell *matCellDef="let row">{{ row.firstName }} {{ row.lastName }}</td>
    </ng-container>

    <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef>{{ 'REAL_ESTATE_AGENT.OFFICE.TABLE.EMAIL' | translate }}</th>
        <td mat-cell *matCellDef="let row">{{ row.email }}</td>
    </ng-container>

    <ng-container matColumnDef="phoneNumber">
        <th mat-header-cell *matHeaderCellDef>{{ 'REAL_ESTATE_AGENT.OFFICE.TABLE.PHONE_NUMBER' | translate }}</th>
        <td mat-cell *matCellDef="let row">{{ row.phoneNumber }}</td>
    </ng-container>

    <ng-container matColumnDef="remarks">
        <th mat-header-cell *matHeaderCellDef>{{ 'REMARK.LABEL_PLURAL' | translate }}</th>
        <td mat-cell *matCellDef="let row">
            <span *ngIf="row | isNotActiveRealEstateAgent">
                {{ ((row | isInactiveRealEstateAgent) ? 'UI.NO_ACCESS' : 'UI.DRAFT') | translate }}
            </span>
        </td>
    </ng-container>

    <!-- Header and Row Declarations -->
    <tr mat-header-row *matHeaderRowDef="columns" class="u-hide-up-to-and-including-phone-landscape"></tr>
    <tr mat-row *matRowDef="let row; columns: columns" [ngClass]="{ disabled: row | isInactiveRealEstateAgent }"></tr>
</table>

<ng-template #inactiveTooltip>
    <p>{{ 'UI.NO_ACCESS' | translate }}</p>
</ng-template>

<ng-template #draftTooltip>
    <p>{{ 'UI.DRAFT' | translate }}</p>
</ng-template>
