import { AfterViewInit, Directive, ElementRef, Input, Renderer2 } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Directive({
    selector: '[unknownIfEmpty]',
})
export class UnknownIfEmptyDirective implements AfterViewInit {
    @Input() considerEmpty: string;

    constructor(
        private el: ElementRef,
        private renderer: Renderer2,
        private translateService: TranslateService
    ) {}

    ngAfterViewInit() {
        let innerText = this.el.nativeElement.innerHTML.trim();
        if (!innerText || innerText === this.considerEmpty) {
            this.renderer.setProperty(this.el.nativeElement, 'innerHTML', this.translateService.instant('COMPANION.GENERAL.UNKNOWN'));
            this.renderer.addClass(this.el.nativeElement, 'u-color-gray-light');
        }
    }
}
