<form id="electricty-form" class="__electricity-form" [formGroup]="electricityForm">
    <div class="u-flex-row u-flex-justify-content-space-between u-margin-bottom">
        <div class="u-flex-row u-flex-justify-content-flex-start">
            <smvd-ui-svg-illustration
                [svg]="svgIllustration.MetersElectricity"
                class="u-icon-24 u-margin-right-half"
                [class.u-color-gray-dark]="activeControl.value"
                [class.u-color-gray-light]="!activeControl.value"
            ></smvd-ui-svg-illustration>
            <h5 [class.u-color-gray-light]="!activeControl.value">{{ 'ELECTRICITY' | translate }}</h5>
        </div>
        <mat-slide-toggle *ngIf="electricityForm.enabled" [formControlName]="fields.Active"></mat-slide-toggle>
    </div>

    <ng-container *ngIf="activeControl.value; else inactive">
        <app-select-input
            id="meter-type-field"
            [custom]="false"
            width="auto"
            [formControlName]="fields.MeterType"
            [direction]="uiDirection.Vertical"
            [options]="meterTypeOptions"
            class="__meter-type-field"
        ></app-select-input>

        <smvd-ui-text-input
            width="100%"
            [formControlName]="fields.EanCode"
            [label]="'EAN_NUMBER.LABEL' | translate"
            [placeholder]="placeholders.eanPlaceholder"
        ></smvd-ui-text-input>
        <div [ngSwitch]="meterTypeControl.value" class="u-margin-bottom">
            <!-- single -->
            <ng-container *ngSwitchCase="meterType.Single">
                <smvd-ui-meter-reading-input
                    width="100%"
                    [formControlName]="fields.SingleMeterReading"
                    [label]="'METER_READING_DAY' | translate"
                    [placeholder]="placeholders.consumptionkWhLabel | translate"
                ></smvd-ui-meter-reading-input>
            </ng-container>

            <!-- double -->
            <ng-container *ngSwitchCase="meterType.Double">
                <smvd-ui-meter-reading-input
                    width="100%"
                    [formControlName]="fields.DoubleDayMeterReading"
                    [label]="'METER_READING_DAY' | translate"
                    [placeholder]="placeholders.consumptionkWhLabel | translate"
                ></smvd-ui-meter-reading-input>
                <smvd-ui-meter-reading-input
                    width="100%"
                    [formControlName]="fields.DoubleNightMeterReading"
                    [label]="'METER_READING_NIGHT' | translate"
                    [placeholder]="placeholders.consumptionkWhLabel | translate"
                ></smvd-ui-meter-reading-input>
            </ng-container>

            <!-- digital -->
            <ng-container *ngSwitchCase="meterType.Digital">
                <app-check-input
                    [custom]="false"
                    width="auto"
                    [formControlName]="fields.AutomaticDigitalReadings"
                    [option]="automaticDigitalReadingOption"
                ></app-check-input>
                <app-date-input width="100%" [formControlName]="fields.MovingDate"></app-date-input>

                <smvd-ui-meter-reading-input
                    width="100%"
                    [formControlName]="fields.ConsumptionDayMeterReading"
                    [label]="'ENERGY.METER_READING.DIGITAL.CONSUMPTION_DAY' | translate"
                    [placeholder]="placeholders.consumptionkWhLabel | translate"
                ></smvd-ui-meter-reading-input>
                <smvd-ui-meter-reading-input
                    width="100%"
                    [formControlName]="fields.ConsumptionNightMeterReading"
                    [label]="'ENERGY.METER_READING.DIGITAL.CONSUMPTION_NIGHT' | translate"
                    [placeholder]="placeholders.consumptionkWhLabel | translate"
                ></smvd-ui-meter-reading-input>

                <app-check-input
                    [custom]="false"
                    width="auto"
                    [formControlName]="fields.SolarPanels"
                    [option]="solarPanelsOption"
                ></app-check-input>

                <ng-container *ngIf="solarPanelsControl.value">
                    <smvd-ui-meter-reading-input
                        width="100%"
                        [formControlName]="fields.InjectionDayMeterReading"
                        [label]="'ENERGY.METER_READING.DIGITAL.INJECTION_DAY' | translate"
                        [placeholder]="placeholders.injectionkWhLabel | translate"
                    ></smvd-ui-meter-reading-input>
                    <smvd-ui-meter-reading-input
                        width="100%"
                        [formControlName]="fields.InjectionNightMeterReading"
                        [label]="'ENERGY.METER_READING.DIGITAL.INJECTION_NIGHT' | translate"
                        [placeholder]="placeholders.injectionkWhLabel | translate"
                    ></smvd-ui-meter-reading-input>
                </ng-container>
            </ng-container>
        </div>

        <app-check-input
            [custom]="false"
            width="auto"
            [formControlName]="fields.ExclusiveNight"
            [option]="exclusiveNightOption"
        ></app-check-input>
        <app-icon
            appTooltip
            icon="info"
            [template]="exclusiveNightTemplate"
            [alignment]="alignment.Right"
            class="u-margin-left-xs"
            [hidePointer]="true"
        ></app-icon>

        <smvd-ui-meter-reading-input
            *ngIf="exclusiveNightControl.value"
            width="100%"
            [formControlName]="fields.ExclusiveNightMeterReading"
            [label]="'METER_READING_EXCLUSIVE_NIGHT' | translate"
            [placeholder]="placeholders.consumptionkWhLabel | translate"
        ></smvd-ui-meter-reading-input>
    </ng-container>
</form>

<ng-template #inactive
    ><p class="u-color-gray-dark" id="inactive-text">{{ 'ENERGY.METER_READING.INACTIVE.ELECTRICITY' | translate }}</p></ng-template
>

<ng-template #exclusiveNightTemplate>
    <p>{{ 'ENERGY.METER_READING.EXCLUSIVE_NIGHT.TOOLTIP' | translate }}</p>
</ng-template>
