<ng-container *ngIf="data">
    <div *ngIf="{ isPhone: uiSandbox.upToAndIncludingPhoneLandscape$ | async } as vm"
         class="__main u-container-lg u-margin-0-auto">
        <div [class.u-margin-bottom]="vm.isPhone" class="u-flex-row u-w100p">
            <div class="__image-container u-padding u-margin-right"><img [src]="data.logo"
                                                                         alt="Logo {{ data.logoAlt }}"/></div>
            <div
                class="u-flex-row u-flex-justify-content-space-between u-flex-grow-1 u-flex-align-items-end u-padding-bottom-xs">
                <div [class.u-margin-right-double]="!vm.isPhone">
                    <div *ngIf="data.breadcrumb" class="u-flex-row u-font-size-small u-color-muted u-display-block u-margin-bottom-xs"
                         id="breadcrumb">
                        <ng-container *ngFor="let item of data.breadcrumb; let last = last">
                            <a *ngIf="item.url; else noUrl" [href]="item.url | localize"
                               class="u-color-muted u-link-inverted">{{
                                    item.label
                                }}</a>
                            <span *ngIf="!last" class="u-padding-x-axis-half">|</span>

                            <ng-template #noUrl>
                                <span>{{ item?.label }}</span>
                            </ng-template>
                        </ng-container>
                    </div>
                    <div [class.__header--activated]="activated" [class.__header--not-activated]="!activated"
                         class="__header">
                        <h1 class="u-font-size-xm __header__title">{{ data.name }}</h1>
                        <smvd-ui-real-estate-group-activation-stage
                            [size]="vm.isPhone ? uiSize.Sm : uiSize.Default"
                            [state]="data.meta.activationStage"
                        ></smvd-ui-real-estate-group-activation-stage>
                    </div>
                    <ui-real-estate-group-kpi
                        *ngIf="!data?.address; else address"
                        [cols]="vm.isPhone ? 2 : 0"
                        [meta]="data.meta"
                    ></ui-real-estate-group-kpi>
                </div>
                <ng-container *ngIf="!vm.isPhone" [ngTemplateOutlet]="score"></ng-container>
            </div>
        </div>
        <ng-container *ngIf="vm.isPhone" [ngTemplateOutlet]="score"></ng-container>
        <ng-content select="[footer]"></ng-content>
    </div>
</ng-container>

<ng-template #score>
    <div class="u-flex-row u-flex-align-items-end">
        <smvd-ui-match-score
            *ngIf="data.scores?.overall"
            [meta]="data.scores?.overall"
            [showDefault]="true"
            class="u-display-block"
        ></smvd-ui-match-score>
        <smvd-ui-icon
            *ngIf="!data.personalScore && !data.noReviews"
            [placement]="uiPlacement.Top"
            [template]="unverified"
            appTooltip
            class="__info u-margin-left-half u-color-gray"
            icon="info"
        ></smvd-ui-icon>

        <smvd-ui-icon
            *ngIf="data.noReviews"
            [placement]="uiPlacement.Top"
            [template]="noReviews"
            appTooltip
            class="__info u-margin-left-half u-color-gray"
            icon="info"
        ></smvd-ui-icon>
    </div>
</ng-template>

<ng-template #address>
    <div class="u-flex-row u-flex-align-items-center u-color-muted u-font-size-sm-alt">
        <smvd-ui-icon [appearance]="iconAppearance.Outlined" [size]="iconSizes.Sm" class="u-margin-right-xs"
                      icon="near_me"></smvd-ui-icon>
        <span class="u-display-inline-block u-line-height-default">{{ data.address }}</span>
    </div>
</ng-template>

<ng-template #unverified>
    <p class="u-font-size-small">{{ 'MATCH.MATCH_SCORE.GROUP_REVIEWS' | translate }}</p>
</ng-template>

<ng-template #noReviews>
    <p class="u-font-size-small">{{ 'MATCH.MATCH_SCORE.NO_REVIEWS' | translate }}</p>
</ng-template>
