import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FeaturePermissionsSandbox } from '@app/feature-scope/sandboxes/feature-permissions.sandbox';
import { SendGiftModal } from '@app/gift/modals/send-gift/send-gift.modal';
import { MoveDetailTab } from '@app/real-estate-agent/modals/move-detail/move-detail.constants';
import { SocialSharingSandbox } from '@app/real-estate-agent/sandboxes/social-sharing.sandbox';
import { RealEstateAgentModalsSandbox } from '@app/real-estate-agent/services/real-estate-agent-modals.sandbox';
import { AppNpsReviewsTemplateService } from '@app/reviews/components/nps-reviews-template/nps-reviews-template.service';
import { AnalyticsEventsEnum, AnalyticsService, FeatureScope, FeatureScopeSandbox, Note, NpsReview, ReviewSource } from '@smooved/core';
import {
    Brand,
    ButtonAppearance,
    DropDownMenuXPosition,
    ModalSandbox,
    NpsReviewsTemplateService,
    UiContext,
    UiIconSize,
} from '@smooved/ui';
import { Observable, of } from 'rxjs';

@Component({
    selector: 'app-nps-actions-full',
    templateUrl: './nps-actions-full.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NpsActionsFullComponent {
    public sharingDisabled: boolean;
    public readonly uiContext = UiContext;
    public readonly uiIconSize = UiIconSize;
    public readonly featureScope = FeatureScope;
    public readonly buttonAppearance = ButtonAppearance;
    public readonly brands = Brand;
    public readonly menuPositions = DropDownMenuXPosition;
    public readonly reviewSources = ReviewSource;

    public featureReviewShare$: Observable<boolean> = this.featurePermissionsSandbox.featureBlocked$(FeatureScope.ReviewShare);
    public featureReviewNote$: Observable<boolean> = this.featurePermissionsSandbox.featureBlocked$(FeatureScope.ReviewNote);

    @Input() public review: any;
    @Input() public npsReviewsTemplateService: NpsReviewsTemplateService;

    constructor(
        public readonly featurePermissionsSandbox: FeaturePermissionsSandbox,
        public readonly featureScopeSandbox: FeatureScopeSandbox,
        private readonly realEstateAgentModalsSandbox: RealEstateAgentModalsSandbox,
        private readonly modalSandbox: ModalSandbox,
        private readonly socialSharingSandbox: SocialSharingSandbox,
        private readonly analyticsService: AnalyticsService
    ) {}

    public openCreateNote(review: NpsReview, isPublic: boolean, note?: Note): void {
        (this.npsReviewsTemplateService as AppNpsReviewsTemplateService).openCreateNote(review, isPublic, note);
    }

    public openDetail(review: NpsReview): void {
        if (review.isAnonymous) {
            return;
        }

        const data = {
            id: review.moveId,
            patchedSubject: of(false),
            options: { activeTab: MoveDetailTab.nps },
        };

        this.realEstateAgentModalsSandbox.openTransfereeDetail(review.moveId, data);
    }

    public openSendGift(review: NpsReview): void {
        const data = {
            data: review.moveId,
        };
        this.modalSandbox.openModal(
            SendGiftModal,
            data,
            this.handleSendGiftModalClose(review),
            SendGiftModal,
            data,
            this.handleSendGiftModalClose(review)
        );
    }

    public shareLinkedIn(review: NpsReview): void {
        this.socialSharingSandbox.shareOnLinkedIn(review);
    }

    public shareFacebook(review: NpsReview): void {
        this.socialSharingSandbox.shareOnFacebook(review);
    }

    public shareInstagram(review: NpsReview): void {
        this.socialSharingSandbox.shareOnInstagram(review);
    }

    public download(review: NpsReview): void {
        this.socialSharingSandbox.download(review);
    }

    public onFilterSelection(filter: string, value: any): void {
        this.analyticsService.sendEvent(AnalyticsEventsEnum.ReviewsInsightsFilterChanged, {
            filter,
            value: JSON.stringify(value),
        });
    }

    private handleSendGiftModalClose = (_: NpsReview): ((reload: boolean) => void) => {
        return (reload: boolean): void => {
            if (reload) {
                this.npsReviewsTemplateService.refresh();
            }
        };
    };
}
