<app-two-panel-modal [loading]="loading">
    <h5 modal-header>{{ 'REVIEWS.WIDGET_INFLUENCER_SCORE.CONFIGURE.LABEL' | translate }}</h5>
    <div modal-left>
        <form [formGroup]="form">
            <div class="u-margin-bottom-double">
                <label class="u-display-block u-margin-bottom-half"
                    >{{ 'REVIEWS.WIDGET_INFLUENCER_SCORE.CONFIGURE.ORIENTATION' | translate }}</label
                >
                <mat-button-toggle-group [formControlName]="formFields.Direction" class="u-w100p">
                    <mat-button-toggle *ngFor="let option of directionOptions" class="u-w50p" [value]="option.value">
                        {{ option.labelResource | translate }}
                    </mat-button-toggle>
                </mat-button-toggle-group>
            </div>
            <div class="u-margin-bottom-double">
                <label class="u-display-block u-margin-bottom-half"
                    >{{ 'REVIEWS.WIDGET_INFLUENCER_SCORE.CONFIGURE.FORMAT' | translate }}</label
                >
                <mat-button-toggle-group [formControlName]="formFields.Size" class="u-w100p">
                    <mat-button-toggle *ngFor="let option of sizeOptions" class="u-w50p" [value]="option.value">
                        {{ option.labelResource | translate }}
                    </mat-button-toggle>
                </mat-button-toggle-group>
            </div>
            <app-select-input
                class="u-display-block"
                [label]="'REVIEWS.WIDGET_INFLUENCER_SCORE.CONFIGURE.SELECT_LANGUAGE' | translate"
                [options]="languageOptions"
                [custom]="false"
                [formControlName]="formFields.Language"
                [direction]="direction.Horizontal"
            ></app-select-input>
            <div class="u-flex u-flex-justify-content-space-between u-margin-bottom u-margin-top-double">
                <div>{{ 'REVIEWS.WIDGET_INFLUENCER_SCORE.CONFIGURE.TOGGLE_SHADOW' | translate }}</div>
                <mat-slide-toggle [formControlName]="formFields.HasShadow"></mat-slide-toggle>
            </div>
            <div class="u-flex u-flex-justify-content-space-between u-margin-bottom">
                <div>{{ 'REVIEWS.WIDGET_INFLUENCER_SCORE.CONFIGURE.TOGGLE_BACKGROUND' | translate }}</div>
                <mat-slide-toggle [formControlName]="formFields.HasBackground"></mat-slide-toggle>
            </div>
            <div class="u-flex u-flex-justify-content-space-between u-margin-bottom">
                <div>{{ 'REVIEWS.WIDGET_INFLUENCER_SCORE.CONFIGURE.TOGGLE_MONOCHROME' | translate }}</div>
                <mat-slide-toggle [formControlName]="formFields.IsMonochrome"></mat-slide-toggle>
            </div>
            <app-color-input *ngIf="form.controls[formFields.IsMonochrome].value" [formControlName]="formFields.Color"></app-color-input>
        </form>
    </div>
    <div modal-right>
        <div class="widget-viewer u-padding-double u-margin-bottom-double" [class.__dark-theme]="darkTheme">
            <div class="u-flex u-flex-align-items-center u-flex-justify-content-center u-w100p u-h100p">
                <wis-widget-v2
                    #widget
                    [key]="externalInfluencer.key"
                    [language]="form.controls[formFields.Language].value"
                    [shadow]="form.controls[formFields.HasShadow].value"
                    [background]="form.controls[formFields.HasBackground].value"
                    [direction]="form.controls[formFields.Direction].value"
                    [size]="form.controls[formFields.Size].value"
                    [color]="form.controls[formFields.IsMonochrome].value ? form.controls[formFields.Color].value : null"
                ></wis-widget-v2>
            </div>
            <div class="__theme-button-container">
                <button
                    app-button
                    [title]="'REVIEWS.WIDGET_INFLUENCER_SCORE.CONFIGURE.TOGGLE_DARK_THEME' | translate"
                    appearance="icon"
                    [icon]="toggleThemeIcon"
                    (click)="toggleTheme()"
                ></button>
                <button
                    *ngIf="authenticationSandbox.isImpersonatedRealEstateAgent$ | async"
                    app-button
                    appearance="icon"
                    icon="download"
                    (click)="screenshot()"
                    class="u-margin-left-half"
                ></button>
            </div>
        </div>
        <p class="u-margin-bottom-half">{{ 'REVIEWS.WIDGET_INFLUENCER_SCORE.CONFIGURE.COPY_SCRIPT' | translate }}</p>
        <app-copy-to-clipboard>
            <pre>{{scriptTag}}</pre>
        </app-copy-to-clipboard>

        <p class="u-margin-top-double u-margin-bottom-half">{{ 'REVIEWS.WIDGET_INFLUENCER_SCORE.CONFIGURE.COPY_BODY' | translate }}</p>
        <app-copy-to-clipboard>
            <pre>{{bodyTag}}</pre>
        </app-copy-to-clipboard>
    </div>
</app-two-panel-modal>
