import { AddressUtils, DateUtils, DbUtils, ExternalInfluencer, Language, NpsReview, RealEstateAgency, StringUtils } from '@smooved/core';
import { environment } from '../../environments/environment';
import { translator } from '../../i18n/server';

const externalInfluencerUri = ':companyPageUri/:language/:externalinfluencerUrl';

export const buildGoogleRatingDataForReview = (
    externalInfluencer: ExternalInfluencer,
    review: NpsReview,
    language = Language.NL
): string => {
    if (!externalInfluencer) return;
    const externalInfluencerUrl = new URL(
        StringUtils.parseUri(externalInfluencerUri, {
            companyPageUri: environment.houseMatchCompanyPageUri,
            language,
            externalinfluencerUrl: externalInfluencer.url,
        })
    ).toString();

    const reviewUrl = `${externalInfluencerUrl}/${translator.translate(language, 'LOCALIZED_ROUTES.reviews')}/${DbUtils.getStringId(review)}`;

    const author = review.isAnonymous
        ? {}
        : {
              author: {
                  '@type': 'Person',
                  name: generateAuthorName(review),
              },
          };

    const location = review.location as RealEstateAgency;
    const itemReviewed = DbUtils.getStringId(location)
        ? {
              itemReviewed: {
                  '@type': 'RealEstateAgent',
                  name: location?.name,
                  '@id': `${environment.houseMatchCompanyPageUri}/location/${DbUtils.getStringId(location)}`,
                  url: `${externalInfluencerUrl}/${translator.translate(language, 'LOCALIZED_ROUTES.location')}/${location?.slug.split('/')[1]}`,
                  image: `https://assets.housematch.be/${DbUtils.getStringId(externalInfluencer.realEstateGroup)}/cover.webp`,
                  address: {
                      '@type': 'PostalAddress',
                      streetAddress: AddressUtils.buildFormattedStreet(review),
                      addressLocality: location?.address?.city,
                      postalCode: location?.address?.zipCode,
                      addressCountry: location?.address?.country || 'BE',
                  },
              },
          }
        : {};

    let data = {
        '@context': 'http://schema.org',
        '@type': 'Review',
        name: review.generatedSummary || review.transactionalTitle,
        reviewBody: review.suggestion,
        url: reviewUrl,
        '@id': reviewUrl,
        datePublished: DateUtils.format(review.createdOn),
        ...author,
        reviewRating: {
            '@type': 'Rating',
            bestRating: '10',
            worstRating: '0',
            ratingValue: review.score,
        },
        ...itemReviewed,
        publisher: {
            '@type': 'Organization',
            name: 'HouseMatch',
            sameAs: 'https://housematch.be',
            url: 'https://housematch.be',
            logo: `${environment.houseMatchCompanyPageUri}/assets/favicon/favicon-32x32.png`,
        },
    };

    return JSON.stringify(data);
};

const generateAuthorName = (review: NpsReview) => {
    return `${StringUtils.capitalize(review.createdBy?.firstName)} ${StringUtils.capitalize(review.createdBy?.lastName[0])}.`;
};
