<ui-dropdown-menu-button
    class="__more-actions"
    icon="more_vert"
    [appearance]="buttonAppearance.Icon"
    [context]="uiContext.Muted"
    *ngIf="{ isSmooved: review.source === reviewSources.Smooved } as vm"
>
    <button uiDropdownMenuItem (click)="openEditReview(review)" [disabled]="!vm.isSmooved">
        {{ 'ANALYTICS.REVIEWS.ACTIONS.EDIT' | translate }}
    </button>
    <button uiDropdownMenuItem (click)="openDetail(review)" [disabled]="!vm.isSmooved || !review.moveId">
        {{ 'ANALYTICS.REVIEWS.ACTIONS.OPEN_MOVE_DETAIL' | translate }}
    </button>
</ui-dropdown-menu-button>
