<ng-container *ngIf="hasOptions()">
    <ui-dropdown-menu-button appearance="icon" icon="add_circle_outline" class="u-color-accent-co">
        <button
            *ngFor="let item of allowedServices; index as index; trackby: trackByService"
            uiDropdownMenuItem
            [disabled]="!item.canExecute"
            (click)="item.callback()"
        >
            {{ item.label | translate }}
        </button>
    </ui-dropdown-menu-button>
</ng-container>
